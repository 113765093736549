import { IconButton } from "@mui/material";
import Avatar from "../Avatar/Avatar";
import Tag from "../Tags/Tags";
import { OutlineBtn } from "../Button/Button";
import { ThumbsDownIcon, ThumbsUpIcon } from "../Icons";
import { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";

export default function NewsComment({ comment, postId }) {
  const [likeState, setLikeState] = useState(false);
  const [dislikeState, setDislikeState] = useState(false);
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);        
  const [error, setError] = useState(null);

  const { currentUser } = useContext(AuthenticationContext);

  useEffect(() => {
    if(currentUser){
      // get like state
      const commentRef = firebase.firestore().collection("scrappedNews").doc(postId).collection("comments").doc(comment.id);
  
      commentRef.onSnapshot((snapshot) => {
        const commentData = snapshot.data();
        setLikeState(commentData.likedBy && commentData.likedBy.includes(currentUser.uid));
        setDislikeState(commentData.dislikedBy && commentData.dislikedBy.includes(currentUser.uid));
        setLikes(commentData.likes || 0);
        setDislikes(commentData.dislikes || 0);
      });
    }
    
  }, [comment.id]);

  const handleLike = (updateData, doAdd) => {
    if (doAdd) {
      updateData.likes = firebase.firestore.FieldValue.increment(1);
      updateData.likedBy = firebase.firestore.FieldValue.arrayUnion(currentUser.uid);
    } else {
      updateData.likes = firebase.firestore.FieldValue.increment(-1);
      updateData.likedBy = firebase.firestore.FieldValue.arrayRemove(currentUser.uid);
    }
  };

  const handleDislike = (updateData, doAdd) => {
    if (doAdd) {
      updateData.dislikes = firebase.firestore.FieldValue.increment(1);
      updateData.dislikedBy = firebase.firestore.FieldValue.arrayUnion(currentUser.uid);
    } else {
      updateData.dislikes = firebase.firestore.FieldValue.increment(-1);
      updateData.dislikedBy = firebase.firestore.FieldValue.arrayRemove(currentUser.uid);
    }
  };

  const toggleLikeDislike = async (commentId, action) => {
    if(!currentUser){
      setError("Please login to like or dislike")
      return
    }

    // Get the comment document
    const commentRef = firebase.firestore().collection("scrappedNews").doc(postId).collection("comments").doc(commentId);
    const commentDoc = await commentRef.get();

    if (commentDoc.exists) {
      const commentData = commentDoc.data();
      const updateData = {};

      // Check if the current user has previously liked or disliked the comment
      const hasLiked = commentData.likedBy && commentData.likedBy.includes(currentUser.uid);
      const hasDisliked = commentData.dislikedBy && commentData.dislikedBy.includes(currentUser.uid);

      // Determine the action and update the comment accordingly
      if (action === "like") {
        if (hasDisliked) {
          handleDislike(updateData, false);
        }

        handleLike(updateData, !hasLiked);
      } else if (action === "dislike") {
        if (hasLiked) {
          handleLike(updateData, false);
        }

        handleDislike(updateData, !hasDisliked);
      }

      // Update the Firestore document with the changes
      await commentRef.update(updateData);
      console.log("updated");
    } else {
      console.log("no such document");
    }
  };

  return (
    <div className="flex flex-col align-top py-5 text-sm lg:text-base border-b">
      <div className="flex gap-5">
        <div className="shrink-0">
          <Avatar />
        </div>
        <div className="flex flex-col grow">
          <h2 className="text-lg md:text-md lg:text-xl font-bold ">{comment.data.username}</h2>
          <div className="flex gap-2 mt-4">
            <Tag text="AAPL" />
            <Tag text="Bearish" addClass="!text-[#FF413C] !bg-[#FFC0C0]" />
          </div>
          <div className="text-gray-500 mt-3 text-[16px]">{comment.data.comment}</div>
          <div className="flex justify-between items-center">
            {
              error ?
              <div className="text-red-500">
              {error}
            </div>
            :
            <div></div>
            }
          <div className="self-end flex gap-5">
            <div className="flex gap-2">
              <div className="flex gap-1 items-center">
                <IconButton onClick={() => toggleLikeDislike(comment.id, "like")}>
                  <ThumbsUpIcon fill="navy" size="25px" />
                </IconButton>
                <span className="font-semibold">{likes}</span>
              </div>

              <div className="flex gap-1 items-center">
                <IconButton onClick={() => toggleLikeDislike(comment.id, "dislike")}>
                  <ThumbsDownIcon fill="navy" size="25px" />
                </IconButton>
                <span className="font-semibold">{dislikes}</span>
              </div>
            </div>

            {/* <OutlineBtn text="Reply" /> */}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
