import React, { useEffect, useRef } from 'react';

const TradingViewChartWidget = ({ticker}) => {
    const chartContainerRef = useRef();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
        script.async = true;
        script.innerHTML = `{
            "symbol": "NASDAQ:${ticker}",
            "width": "350",
            "height": "220",
            "locale": "in",
            "dateRange": "3M",
            "colorTheme": "light",
            "isTransparent": false,
            "autosize": false,
            "largeChartUrl": "",
            "noTimeScale": true,
            "chartOnly": false
        }`;

        chartContainerRef.current.appendChild(script);

        // return () => {
        //     // Cleanup: Remove the script when the component unmounts
        //     chartContainerRef.current.removeChild(script);
        // };
    }, []);

    return (
        <div className="tradingview-widget-container" ref={chartContainerRef}>
            <div className="tradingview-widget-container__widget"></div>
        </div>
    );
};

export default TradingViewChartWidget;
