import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import EnterpriseCard from "../../components/Cards/EnterpriseCard";
import NewsBulletinCard from "../../components/Cards/NewsBulletinCard";
import CluterCard from "../../components/Cards/ClusterCard";
import KeyPointCard from "../../components/Cards/KeyPointCard";
import TradeCard from "../../components/Cards/TradeCard";
import { SwiperSlide } from "swiper/react";
import TradeCardCarousel from "../../components/Carousel/TradecardCarousel";
import StockAnalogyCard from "../../components/Cards/StockAnalogyCard";
import EnterPriseNav from "../../components/Navbar/EnterPriseNav";
import TradingViewWidget from "./tradingview-widget";
import { ExploreStockNewsContext } from "../../../services/exploreStocksNews/exploreStocksNews.context";
import LoadingSpinner from "../../components/UIElements/LoadingSpinner";
import TradingViewChartWidget from "../../components/Carousel/TradingViewChartWidget";
import firebase from "firebase";
import { FilledBtn } from "../../components/Button/Button";
import ChatElement from '../../components/Chat/ChatElement.jsx';
import Chatbox from '../../components/ChatBox/ChatBox.jsx';


const EnterpriseLandingScreen = ({ match, history }) => {
  const { myStockNewsE, isNewsELoading, localMyStockNewsE } = useContext(ExploreStockNewsContext);
  const [loadingStockNewsE, setLoadingStockNewsE] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [tickerData, setTickerData] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [showTicker, setShowTicker] = useState([
    { label: "META : Meta Platforms, Inc. Class A Common Stock", ticker: "META", value: "Meta Platforms, Inc. Class A Common Stock" },
    { label: "LULU : lululemon athletica inc.", ticker: "LULU", value: "lululemon athletica inc." },
    { label: "AAPL : Apple Inc.", ticker: "AAPL", value: "Apple Inc." },
    { label: "GOOG : Alphabet Inc. Class C Capital Stock", ticker: "GOOG", value: "Alphabet Inc. Class C Capital Stock" }
  ]);
  const [ChatBox, setChatbox] = useState(false);
  const [newChat, setNewChat] = useState("0");

  const toggleChat = () => setChatbox((prev) => !prev);

  const [modal, setModal] = useState("");
  const [filteredMyStockNewsE, setFilteredMyStockNewsE] = useState(myStockNewsE);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [stockNewsE, setStockNewsE] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const monthName = moment().format("MMMM");

  useEffect(() => {
    setFilteredMyStockNewsE(myStockNewsE);
    const allTicker = myStockNewsE?.map(news => news.tickers).flat();
    const uniqueTickers = [...new Set(allTicker)];
    let v = uniqueTickers?.map((obj) => ({ label: obj, value: obj }));
    let temp = [...v];
    setTickerData(v);
    if (temp?.length > 0) {
      const firstFourItems = temp.slice(0, 5);
      setShowTicker(firstFourItems);
      setTickers(firstFourItems);
    }
  }, [myStockNewsE]);

  async function getNewsDataForTickers(loadMore = false) {
    if (stockNewsE.length === 0) setLoadingStockNewsE(true);
    else setLoadMore(true);

    let aggregatedResults = [];
    let uniqueDocIds = new Set();

    try {
      for (const { ticker } of showTicker) {
        let query = firebase.firestore().collection("scrappedNews")
          .orderBy("added_utc", "desc")
          .where("tickers", "array-contains", ticker)
          .limit(10);
        if (loadMore && lastVisible) query = query.startAfter(lastVisible);
        
        const snapshot = await query.get();
        snapshot.docs.forEach(doc => {
          if (!uniqueDocIds.has(doc.id)) {
            aggregatedResults.push({ id: doc.id, data: doc.data() });
            uniqueDocIds.add(doc.id);
          }
        });
      }
      aggregatedResults.sort((a, b) => b.data.added_utc - a.data.added_utc);

      if (loadMore) setStockNewsE(prevNews => [...prevNews, ...aggregatedResults.slice(0, 9)]);
      else setStockNewsE(aggregatedResults);
    } catch (error) {
      console.error("Error fetching news:", error);
      alert("An error occurred while fetching news. Please try again.");
    } finally {
      setLoadingStockNewsE(false);
      setLoadMore(false);
    }
  }

  const handleLoadMore = () => getNewsDataForTickers(true);

  useEffect(() => {
    setStockNewsE([]);
    getNewsDataForTickers();
  }, [showTicker]);

  return (
    <div className="">
      <EnterPriseNav 
        setSelectedFilters={setSelectedFilters} 
        selectedFilters={selectedFilters} 
        tickers={tickers} 
        setTickers={setTickers} 
        tickerData={tickerData} 
        setShowTicker={setShowTicker} 
        showTicker={showTicker} 
      />
      <div className="container-fluid md:px-7 px-3 mx-auto pb-3 mt-3 overflow-hidden">
        <div className="grid grid-cols-12 gap-5">
          <div className="lg:col-span-3 col-span-12 lg:order-1">
            <EnterpriseCard paddingBottom={"10"} title={"News Bulletin"}>
              <div className="h-[calc(100vh-160px)] overflow-y-auto hide-scrollbar">
                {!loadingStockNewsE ? (
                  <>
                    {stockNewsE.map((news, key) => (
                      <NewsBulletinCard
                        key={key}
                        title={news.data.article_heading}
                        published_utc={news.data.added_utc}
                        description={news.data.description}
                        tickers={news.data.tickers}
                        newsData={news}
                        style={{ border: '1px solid #000' }}
                      />
                    ))}
                    <div className="load_more_btn mb-4 mt-8" onClick={handleLoadMore}>
                      {loadMore ? <LoadingSpinner size={"sm"} /> : <FilledBtn text="Load More" />}
                    </div>
                  </>
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <LoadingSpinner size='sm' />
                  </div>
                )}
              </div>
            </EnterpriseCard>
          </div>

          <div className="lg:col-span-6 col-span-12 lg:order-2">
            <EnterpriseCard paddingBottom="10" title="ArcaGPT">
              <div>
              <Chatbox /> 
              </div>
            </EnterpriseCard>
          </div>

          {/* <div className="lg:col-span-6 col-span-12 lg:order-2">
          <Chatbox /> {}
          </div> */}

          <div className="lg:col-span-3 col-span-12 lg:order-3">
            <div style={{ alignItems: 'right' }}>
              <EnterpriseCard paddingBottom="10" title={"Economic Data & Forecasts"}>
                <div className="h-[calc(100vh-160px)]">
                  <TradingViewWidget />
                </div>
              </EnterpriseCard>
            </div>
          </div>
        </div>
      </div>

      {/* Chat Toggle Button and Chat Element
      <button onClick={toggleChat}>Toggle Chat</button>
      {chatbox && <ChatElement chatbox={chatbox} newChat={newChat} setNewChat={setNewChat} />} */}
    </div>
  );
};

export default EnterpriseLandingScreen;
