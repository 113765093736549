import React from 'react';
import './chatbox.css'; // Import the CSS
import chatIcon from './image.png'; // Adjust the path if necessary
import sendArrow from './image.png';
const Chatbox = () => {
  return (
    <div className="chatbox-layout">
      {/* Header */}
      <h5 className="chatbox-header">ArcaGPT</h5>

      {/* Question List */}
      <div className="chatbox-question-list">
        <div className="chatbox-question">
           <img src={chatIcon} alt="chat icon" className="chatbox-question-icon" />
          <span>What stocks are insiders trading?</span>
        </div>
        <div className="chatbox-question">
        <img src={chatIcon} alt="chat icon" className="chatbox-question-icon" />
          <span>ESG focused companies NASDAQ</span>
        </div>
      </div>

      

      {/* Footer */}
      <div className = "chatbox-sendLayout">
        <input
          type="text"
          className="chatbox-input"
          placeholder="Send a message."
        />
        <button className="chatbox-send-btn">
        <img src={sendArrow} alt="send arrow" />
        </button>
      </div>
      
      {/* Footer Text */}
      <div className="chatbox-footer-text">
        Research Preview by Arcafeed
      </div>
    </div>
  );
};

export default Chatbox;
