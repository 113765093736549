import "./App.css";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
// Routing
// Screens
import {
    Page404,
    EnterpriseLandingScreen,
} from "./components/screens/main";

import ReactGA from "react-ga";

import { useContext, useEffect } from "react";

import LoadingSpinner from "./components/components/UIElements/LoadingSpinner";
import { AuthenticationContext } from "./services/authentication/authentication.context";
import { PlaidContextProvider } from "./services/Plaid/Plaid.context";
import NewsContextProvider from "./services/news/news.context";
import ExploreStockNewsContextProvider from "./services/exploreStocksNews/exploreStocksNews.context";
import "swiper/components/pagination/pagination.min.css";

const TRACKING_ID = "UA-185964762-1";
const App = () => {
    const { userType, isLoggedIn, isLoading, currentUser } = useContext(
        AuthenticationContext
    );
    ReactGA.initialize(TRACKING_ID);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);
    return (
        <ExploreStockNewsContextProvider>
            <Router>
                <Switch>
                    <Route
                        exact
                        path="/"
                        component={EnterpriseLandingScreen}
                        />
                    <Route component={Page404} />
                </Switch>
            </Router>
        </ExploreStockNewsContextProvider>
    );
};

export default App;
