import { IconButton } from "@mui/material";
import Avatar from "../Avatar/Avatar";
import Tag from "../Tags/Tags";
import { OutlineBtn } from "../Button/Button";
import { ThumbsDownIcon, ThumbsUpIcon } from "../Icons";
import { useContext, useEffect, useState } from "react";
import firebase from "firebase";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";
import NewsComment from "./NewsComment";

export default function NewsComments({ postId, commentCount }) {
  const [comments, setComments] = useState([]);
  const [showAllComments, setShowAllComments] = useState(false);

  const { currentUser } = useContext(AuthenticationContext);

  useEffect(() => {
    // get comments from firestore
    firebase
      .firestore()
      .collection("scrappedNews")
      .doc(postId)
      .collection("comments")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        setComments(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
      });
  }, [postId]);

  return (
    <div className="flex flex-col mb-12">
      <h2 className="text-lg md:text-md lg:text-xl mb-3 pb-3 font-bold font-sourceSansPro border-b-2">Comments</h2>

      {comments.map((comment, index) => {
        if (!showAllComments && index >= 2) {
          return null; // Don't render comments beyond the first two when not showing all
        }
        return <NewsComment key={comment.id} comment={comment} postId={postId} />;
      })}

      {commentCount > 0 ? (
        !showAllComments && (
        <button className="self-center flex gap-2 items-center mt-10 text-arca-blue font-bold hover:underline" onClick={()=>setShowAllComments(true)}>
          <span>View All Comments ({commentCount})</span>
          <span className="flex items-center justify-center p-2 rounded-full bg-arca-blue">
            <img src="/down-arrow.svg" alt="down-arrow" className="h-[10px] w-[10px]" />
          </span>
        </button>
        )
      ) : (
        <p className="self-center mt-10 text-arca-blue font-bold">No Comments Yet</p>
      )}
    </div>
  );
}
