import React, { useState } from 'react';
import Select from 'react-select';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
function TickerMutliSelector({
  setSelectedFilters,
  handleClose,
  handleAddTicker,
  open,
  tickerData,
  showTicker,
  setShowTicker
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [tickerOptions,setTickerOptions]=useState([])

  const fetchTickerData = async (searchQuery) => {

    const apiKey ="I4qlkMxHIDnBiY5Ji3TyNtULBnocT3CH";
    const url = `https://api.polygon.io/v3/reference/tickers?search=${searchQuery}&active=true&order=desc&limit=100&apiKey=${apiKey}`;
  
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
     
      const tickerData = await data.results.map(item => ({
        ticker: item.ticker,
        label: item.ticker + " : "+ item.name,
        value: item.name
      }));
     
      setTickerOptions(tickerData)
      
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };



  const handleFilterChange = (newFilter) => {
    setShowTicker(newFilter);
    // setSearchTerm('');
  };

  const handleSearchInputChange = (inputValue) => {
    
    setSearchTerm(inputValue);
    fetchTickerData(inputValue)
  };

  const menuIsOpen = searchTerm.trim() !== '';
  const customDropdownIndicator = ({ innerProps }) => (
    <div {...innerProps} className='px-2'>
      <SearchIcon />
    </div>
  );
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        keepMounted={true}
        onClose={handleClose}
        open={open}
        className="rounded-[20px]"
      >
        <DialogTitle sx={{ position: "sticky", top: 0, left: 0, borderRadius: "10px" }}>
          <div className="flex justify-between items-center">
            <IconButton onClick={handleClose}>
              <span>
                <img src="/close.svg" alt="close-icon" />
              </span>
            </IconButton>
          </div>
        </DialogTitle>

        <DialogContent
          style={{
            height: "400px"
          }}
          className="bg-white-100"
        >
          <div >
          <div className="text-muted font-normal me-2  text-[22px] my-2">
                Add Ticker{" "}
              </div>
          </div>

          <div style={{
            height: "300px",
            overflow: "auto"
          }}>
            <Select
              isMulti
              name="colors"
              options={tickerOptions}
              value={showTicker}
              captureMenuScroll
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleFilterChange}
              onInputChange={handleSearchInputChange}
              menuIsOpen={menuIsOpen}
              components={{
                DropdownIndicator: customDropdownIndicator
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default TickerMutliSelector;
