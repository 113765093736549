import { IconButton } from "@mui/material";
import { FilledBtn } from "../Button/Button";
import "./style.css";
import { useEffect, useState } from "react";
import ChatBox from "../ChatBox/ChatBox";
import ChatElement from "../Chat/ChatElement";
import { X, XCircle } from "@phosphor-icons/react";
import TickerMutliSelector from "../Filter/TickerMutliSelector";
import { Button } from "@material-ui/core";
import CancelIcon from '@mui/icons-material/Cancel';
const EnterPriseNav = ({ setSelectedFilters, tickerData, showTicker, setShowTicker, tickers, setTickers, selectedFilters }) => {
  const [menu, setMenu] = useState("0");
  const [chatbox, setchatbox] = useState("0");
  const [newChat, setNewChat] = useState("0");
  const [open, setOpen] = useState(false);
  console.log("open", open);
  const handleClose = () => {
    setOpen(!open);
  };
  useEffect(() => {
    let temp = [...showTicker];
    setTickers(temp);
  }, [showTicker]);
  const setFilteredValue = new Set();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const handleClick = (e, value) => {
    e.preventDefault();
    console.log("value", value);
    const isSelected = selectedFilters?.includes(value);
    const newSelectedButtons = isSelected
      ? selectedFilters.filter((btn) => btn !== value)
      : [...selectedFilters, value];
    setSelectedFilters(newSelectedButtons);
    if (isSelected) {
      setFilteredValue.delete(value);
    } else {
      setFilteredValue.add(value);
    }
  };
  const [display, setDisplay] = useState("hidden");
  const handleRemove = (e, value) => {
    e.stopPropagation();
    const updatedShowTicker = showTicker.filter((v) => v.value !== value);
    const updatedTickers = tickers.filter((v) => v.value !== value);
    const updateFilter = selectedFilters.filter((v) => v != value);
    setShowTicker(updatedShowTicker);
    setTickers(updatedTickers);
    setSelectedFilters(updateFilter);
  };
  return (
    <div className="top-0 w-full bg-white shadow-nav">
      <div className="flex items-right justify-between px-10 py-4 mx-auto ml-auto">
        <div className="w-[200px] shrink-0">
          <img src="/arcafeed.png" alt="brand-logo" />
        </div>
        {/* Placeholder to keep layout stable where the search bar was */}
        <div className="hidden md:block w-full max-w-[000px]" />
        <div className="all-center md:!hidden ml-auto">
          <img
            src="/mainmenu.svg"
            className="w-[35px]"
            onClick={() => setMenu("1")}
          />
        </div>
        <div
          className={`bg-white flex w-full relative block fixed left-0 h-auto z-40 top-0 main-nav ${menu === "1" ? "" : "nav-hide"}`}
        >
          <div className="flex mb-5 md:hidden">
            <div className="w-[200px] shrink-0">
              <img src="/arcafeed.png" alt="brand-logo" />
            </div>
            <div className="ml-auto all-center">
              <img
                src="/close.svg"
                alt="brand-logo"
                className="w-[35px]"
                onClick={() => setMenu("0")}
              />
            </div>
          </div>
          <div className="items-center justify-between block md:basis-4/12 md:flex ml-auto">
            <div className="items-center gap-1 mt-10 md:flex md:mt-0">
              <TickerMutliSelector setSelectedFilters={setSelectedFilters} setShowTicker={setShowTicker} showTicker={showTicker} tickerData={tickerData} open={open} handleClose={handleClose} />
              {tickers?.slice(0, 5)?.map((obj, index) => (
                <div key={obj.value} className="relative mx-2" onMouseEnter={() => setHoveredIndex(index)}
                  onMouseLeave={() => setHoveredIndex(null)}>
                  <Button
                    onClick={(e) => handleClick(e, obj?.value)}
                    className=" shadow"
                    style={{
                      backgroundColor: 'navy',
                      color: 'white',
                    }}
                  >
                    {obj?.ticker}
                  </Button>
                  {hoveredIndex === index && (
                    <span className="absolute -right-2 -top-4 z-10 cursor-pointer">
                      <CancelIcon style={{ color: 'red' }} onClick={(e) => { handleRemove(e, obj?.value) }} />
                    </span>
                  )}
                </div>
              ))}
              {tickers.length === 0 && (
                <span className="text-navy font-normal me-2 !text-[18px]">
                  Add Ticker:{" "}
                </span>
              )}
              <IconButton
                onClick={() => setOpen(!open)}
                sx={{
                  boxShadow: "rgba(30, 105, 255, 0.4)",
                  borderRadius: "4px",
                  cursor: "pointer"
                }}
              >
                <img
                  src="/add.svg"
                  alt="add-icon"
                  className="p-2 rounded box-shadow"
                />
              </IconButton>
              <IconButton
                className="px-4 !ml-10"
                sx={{
                  boxShadow: "rgba(30, 105, 255, 0.4)",
                  borderRadius: "4px",
                }}
              >
                <div className="flex gap-3 md:relative fixed md:top-auto top-[96px] md:right-[0px] right-[30px] w-auto md:w-auto w-[49px]">
                  <img src="/user-round.svg" alt="user-icon" />
                  <img src="/caret-down.svg" alt="user-icon" />
                </div>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EnterPriseNav;