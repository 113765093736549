import Tag from "../Tags/Tags";
import firebase from "firebase";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../services/authentication/authentication.context";

export default function AddComment({postId}) {
    const [uid, setUid] = useState("")
    const [username, setUsername] = useState("")

    const [comment, setComment] = useState("")
    const [commentPosted, setCommentPosted] = useState(false)

    const [error, setError] = useState("")

    const {currentUser} = useContext(AuthenticationContext)

    useEffect(() => {
        if (currentUser) {
            setUid(currentUser.uid)
            //  get username from firestore
            firebase.firestore().collection("users").doc(currentUser.uid).get().then((doc) => {
                if (doc.exists) {
                    setUsername(doc.data().name)
                } else {
                    console.log("No such document!");
                }
            }).catch((error) => {
                console.log("Error getting document:", error);
            });
        }
    }, [currentUser])

    const AddComment = async (e) => {
        e.preventDefault()

        if(!currentUser){
            setError("Please login to comment")
            return
        }

        const commentData = {
            uid: uid,
            username: username,
            comment: comment,
            likes: 0,
            likedBy: [],
            dislikes: 0,
            dislikedBy: [],
            postId: postId,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        }

        console.log(commentData)

        // add comment to doc of scraped news
        await firebase.firestore().collection("scrappedNews").doc(postId).collection("comments").add(commentData).then((docRef) => {
            console.log("Document written with ID: ", docRef.id);
            // update comment count
            firebase.firestore().collection("scrappedNews").doc(postId).update({
                commentCount: firebase.firestore.FieldValue.increment(1)
            })
        }).catch((error) => {
            console.error("Error adding document: ", error);
        });

        setComment("")
        setCommentPosted(true)
    }

    // clear commentPosted after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setCommentPosted(false)
        }, 5000);
        return () => clearTimeout(timer);
    }, [commentPosted])

    return (
        <form className="flex flex-col text-sm lg:text-base">
            <h2 className="text-lg md:text-md lg:text-xl mb-2 font-bold font-sourceSansPro border-b pb-4">
                Add My Comment
            </h2>

            <div className="flex flex-col">
                <span className="text-gray-400 text-[14px]">Choose a ticker</span>
                <div className="flex gap-2 mt-2">
                    <Tag text="MSFT"
                        addClass="!text-[#182a85] !bg-[#fff] border border-[#182a85]" />
                    <Tag
                        text="Bullish"
                        addClass="!text-[#182a85] !bg-[#fff] border border-[#182a85]"
                    />
                </div>
            </div>

            <div className="mt-10">
                <textarea
                    name="add-comment"
                    id="add-comment"
                    // cols="30"
                    rows="10"
                    className="border border-[#182a85] outline-0 rounded-md w-full py-2.5 px-3 bg-[#FFF] h-32 text-gray-600 focus:border focus:border-navy-blue"
                    placeholder="Enter your comment after selecting a ticker..."
                    onChange={(e) => setComment(e.target.value)}
                    value={comment}
                ></textarea>
            </div>
            <div className="flex justify-between mt-4">
            {
                commentPosted ?
                <div className=" text-lime-500">
                    Comment Posted
                </div>
                :
                error?
                <></>:
                <div>
                </div>
            }
            {
                error ?
                <div className=" text-red-500">
                    {error}
                </div>
                :
                <div>
                </div>
            }
            <div className="">
                <button onClick={AddComment} className="px-4 py-1.5 bg-navy hover:bg-[#374bab] text-white rounded-lg">Post Comment</button>
            </div>
            </div>
        </form>
    );
}
